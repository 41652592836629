import components from './components'
import constant from './const'

import main from './main.json'
import egeInformatika from './ege-informatika.json'
import predprinimatelstvo from './predprinimatelstvo.json'

import osnovyProgrammirovaniya from './osnovy-programmirovaniya.json'
import python from './python.json'
import unity from './unity.json'
import sozdanieSaitov from './sozdanie-saitov.json'
import gameDesign from './game-design.json'
import graphicDesign from './graphic-design.json'
import videoblogging from './videoblogging.json'
import vizualnoeProgrammirovanie from './vizualnoe-programmirovanie.json'
import computernayaGramotnost from './computernaya-gramotnost.json'
import frontend from './frontend.json'
export default {
  components,
  constant,

  main,
  egeInformatika,
  predprinimatelstvo,
  osnovyProgrammirovaniya,
  python,
  unity,
  sozdanieSaitov,
  gameDesign,
  graphicDesign,
  videoblogging,
  vizualnoeProgrammirovanie,
  computernayaGramotnost,
  frontend,
}
